import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import PageWrapper from "../components/PageWrapper"
import Seo from "../components/Seo"
import FeaturedSlider from "../components/FeaturedSlider"
import ContentFeed from "../components/ContentFeed"
import SignupForm from "../components/newsletter/SignupForm"
import { useMediaQuery } from 'react-responsive'
import { LoadingBlock } from "../svg/Loading"

const IndexPage = ( {data} ) => {

  const aboveLg = useMediaQuery({ query: '(min-width: 1025px)' });


    let homeScrollText = null;
    if (data.wpSnippet.autoscrollTextSettings.autoscrollText) {
        const textAreaContent = data.wpSnippet.autoscrollTextSettings.autoscrollText;

        const textArray = textAreaContent.split(/\r?\n/);
	    
        homeScrollText = textArray.map(txt => ({ content: txt }));
    }

    const [isLoaded, setIsLoaded] = React.useState(false);

      React.useEffect(() => {
        setIsLoaded(true);

        return () => {
          setIsLoaded(false);
        }
      },[])

      const featuredPosts = data.allWpPost.edges;
      const featuredGallery = data.allWpGallery.edges;
      const featuredRecords = data.allWpRelease.edges;
  
      const nArrays = [
          featuredPosts,
          featuredGallery,
          featuredRecords
      ]

      const flatArray = [].concat(...nArrays).sort(function(a,b){return new Date(b.node.date) - new Date(a.node.date)});


  return (
    
      <Layout>
        <Seo title="Home" description={`All things music. With friends and family. Artist-centric music management and record label.`} />
        <PageWrapper scrollData={homeScrollText} >

          {isLoaded ? (<>
            <FeaturedSlider autoplay={true} autoplaySpeed={12000} />
            <ContentFeed feedData={flatArray} />
          </>
          ) : (
            <LoadingBlock />
          )}

        <section className={`md:-mx-7 sm:-mx-5 -mx-2 py-10 pt-20 border-t border-color-text px-2 md:px-7 sm:px-5 overflow-x-hidden`}>
          <div className={`lg:flex lg:flex-row lg:items-center lg:-mx-5`}>
            <div className={`lg:block hidden lg:px-5 lg:flex-true`}>
              <div className={`md:mx-auto md:max-w-[20rem] text-center `}>
                <h3 className={`lg:text-4xl tracking-normal italic uppercase`}>The latest and more</h3>
                <p>Subscribe to the LTA newsletter.</p>
              </div>
              
            </div>
            <SignupForm className={`lg:px-5 lg:flex-true`} showHeader={aboveLg ? false : true} headerText={`The latest and more`} />
          </div>
        </section>
        

        </PageWrapper>
        
        
      </Layout>
  )
} 

export default IndexPage

export const query = graphql`
query HomepageContentQuery {
    allWpPost(
        filter: {featuredContent: {featureOnHomepage: {eq: true}}, featuredImageDatabaseId: {ne: null}}
        sort: {fields: date, order: DESC}
        ) {
        edges {
          node {
            id
            title
            date
            contentType {
              node {
                name
              }
            }
            postLinkSettings {
              postExternalLink
            }
            featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            
          }
        }
      }
      allWpGallery(
        filter: {featuredContent: {featureOnHomepage: {eq: true}}, status: {eq: "publish"}, featuredImageDatabaseId: {ne: null}}
        sort: {fields: date, order: DESC}
      ) {
        edges {
          node {
            contentType {
              node {
                name
              }
            }
            id
            title
            date
            presentsGallerySettings {
              eventMediaUrl
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
      allWpRelease(
        filter: {featuredContent: {featureOnHomepage: {eq: true}}, status: {eq: "publish"}, featuredImageDatabaseId: {ne: null}}
        sort: {fields: date, order: DESC}
        ) {
        edges {
          node {
            id
            title
            slug
            date
            ltaRecordsSettings {
                recordArtistSelection {
                ... on WpArtist {
                    id
                    title
                    slug
                }
                }
                recordPreorderLink
                recordPurchaseLink
                recordStreamLink
                releaseDate
                lpNumber
            }
            imprints {
                nodes {
                    name
                    id
                    slug
                }
            }
            contentType {
              node {
                name
              }
            }
            featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
          }
        }
      }
      wpSnippet(id: {eq: "cG9zdDoxMDUz"}) {
        id
        slug
        content
        autoscrollTextSettings {
                autoscrollText
              }
      }
      
}
`