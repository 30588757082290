import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Container from './Container'
import Button from './Button'
import { FancyTitle } from './PageTitles'
import { featuredSlider, slide, mediaSlide, contentSlide } from '../styles/slider.module.scss'
import { PrQuotes } from './FeaturedSlider'

const Banner = props => {


  const { bannerData } = props;

  const bannerImageData = getImage(bannerData.featuredImage?.node?.localFile)



let contentOrder = `md:order-1`;
let mediaOrder = `md:order-2`;

if (bannerData.snippetSettings.flipLayout) {
    contentOrder = `md:order-2`;
    mediaOrder = `md:order-1`;
}

const slideLink1 = bannerData.snippetSettings.snippetLink1;
const slideLink2 = bannerData.snippetSettings.snippetLink2;

let btnTitle = "Learn More";

    return (

        <div className={`${featuredSlider} pb-10`}>
            <Container>
            <div className={`flex flex-row items-center md:flex-nowrap flex-wrap md:-m-5 lg:-m-7 ${slide}`}>
                <div className={`max-w-full flex-full md:flex-[45%] w-full md:max-w-[45%] md:p-5 lg:p-7 text-center ${contentOrder} ${contentSlide}`}>
                    {bannerData.snippetSettings.mainSnippetHeader &&
                        
                        <FancyTitle>{bannerData.snippetSettings.mainSnippetHeader}</FancyTitle>
                    }


                    {bannerData.content &&

                    <div dangerouslySetInnerHTML={{__html: bannerData.content}} />
                    }

                    { bannerData.snippetSettings.includePrQuotes === true &&
                          <PrQuotes prData={bannerData.snippetSettings.quoteSelection} />
                        }


                    {(slideLink1 !== null || slideLink2 !== null) &&
                        <div className={`relative z-30 mt-10 space-x-3`}>

                            {
                            slideLink1 &&

                            <>
                                {slideLink1.target === "_blank" ? (
                                    <Button href={slideLink1.url} type={'external'}>{!slideLink1.title ? btnTitle : slideLink1.title}</Button>
                                ) : (
                                    <Button to={slideLink1.url} type={'internal'}>{!slideLink1.title ? btnTitle : slideLink1.title}</Button>
                                )}
                            </>


                            

                    
                            }

                            {
                            slideLink2 &&


                            <>
                                {slideLink2.target === "_blank" ? (
                                    <Button variation={`secondary`} href={slideLink2.url} type={`external`}>{!slideLink2.title ? btnTitle : slideLink2.title}</Button>
                                ) : (
                                    <Button variation={`secondary`} to={slideLink2.url} type={`internal`}>{!slideLink2.title ? btnTitle : slideLink2.title}</Button>
                                )}
                            </>

                    
                            }
                        
                        </div>
                        }
                </div>
                    
                    
                    {bannerData.featuredImage &&
                        <div className={`md:flex-true md:p-5 lg:p-7 ${mediaOrder} ${mediaSlide}`}>
                        <GatsbyImage image={getImage(bannerImageData)} alt={bannerData.featuredImage.altText || bannerData.title} />
                        </div>
                    }
                </div>


            </Container>

        </div>
    )

}

export default Banner