// extracted by mini-css-extract-plugin
export var contentSlide = "slider-module--content-slide--4ZW68";
export var contentWrapper = "slider-module--content-wrapper--ugF+y";
export var featuredSlider = "slider-module--featured-slider--RBtEY";
export var itemSlide = "slider-module--item-slide--r0DVM";
export var itemSlider = "slider-module--item-slider--fnoFZ";
export var mediaSlide = "slider-module--media-slide--NxOs7";
export var mediaWrapper = "slider-module--media-wrapper--7T6LW";
export var next = "slider-module--next--YfsQS";
export var preheader = "slider-module--preheader--zFjYo";
export var previous = "slider-module--previous--Eap7g";
export var slide = "slider-module--slide--v5Uc-";
export var sliderSpillRight = "slider-module--slider-spill-right--a2rHX";
export var subheader = "slider-module--subheader--VwVRL";